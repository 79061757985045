import React, { Component } from 'react'
import * as THREE from 'three';
import { Interaction } from 'three.interaction/src/index';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls'

import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';

import { EffectComposer } from 'three/examples//jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples//jsm/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/examples//jsm/postprocessing/ShaderPass.js';
// import { UnrealBloomPass } from 'three/examples//jsm/postprocessing/UnrealBloomPass.js';
import { UnrealBloomPass } from "./TransparentBackgroundFixedUnrealBloomPass"

class ThreeDimensionalWorld extends Component {
  constructor(props) {
    super(props)

    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
    this.animate = this.animate.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.contentLoaded = props?.contentLoaded;
    this.wrapper = this.props.config.container;
  }

  componentDidMount() {

    this.autoanimation = false
    const camera = new THREE.PerspectiveCamera(
      45,
      this.wrapper.clientWidth / this.wrapper.clientHeight,
      1,
      2000
    );

    this.ENTIRE_SCENE = 0;
    this.BLOOM_SCENE = 1;

		const bloomLayer = new THREE.Layers();
		bloomLayer.set( this.BLOOM_SCENE );

    const params = {
      exposure: .4,
      bloomStrength: .4,
      bloomThreshold: 2,
      bloomRadius: 0,      
    };

    

    camera.position.set(0, 0, this.props.config.camera.current);

    const scene = new THREE.Scene();

    const hemiLight = new THREE.HemisphereLight(0xffffff, 0x444444, .5);
    hemiLight.position.set(0, 10000, 0);
    scene.add(hemiLight);


    const dirLight = new THREE.DirectionalLight(0xFFFFFF, 1.25);
    dirLight.position.set(0, 100, 2000);
    dirLight.castShadow = true;
    scene.add(dirLight);

    dirLight.shadow.mapSize.width = 512; // default
    dirLight.shadow.mapSize.height = 512; // default
    dirLight.shadow.camera.near = 0.5; // default
    dirLight.shadow.camera.far = 500; // default

    const darkMaterial = new THREE.MeshBasicMaterial( { color: "black" } );
    const materials = {};

    const map = new THREE.TextureLoader().load(`${this.props.config.path}/images/add.png`);
    const material = new THREE.SpriteMaterial({
      map: map,
      color: 0xffffff,
      alpha: 0
    });


    const sprite = new THREE.Sprite(material);
    sprite.castShadow = true;
    const loader = new GLTFLoader().setPath(`${this.props.config.path}/models/Ottimizzato_09/`);
    
    
    loader.load('Ottimizzato_09.glb', (gltf) => {

      const object = gltf.scene
      
      object.scale.set(3, 3, 3);
      object.rotation.set(-0.68,-1.5708, 0);

      const animations = gltf.animations;
      if(animations.length > 0) {
        this.mixer = new THREE.AnimationMixer( object );
        animations.forEach( anim => {
          this.mixer.clipAction( anim ).play();
        });
      }
    
      object.traverse((child) => {
        if ( child instanceof THREE.Mesh  ) {
          child.material.metalness = 0;
          //child.castShadow = true;
          child.receiveShadow = true;

          if(child.name.toLowerCase().indexOf("pian")!== -1){
            console.log('pian',child.name )
          }

          if(child.name.toLowerCase().indexOf("linea")!== -1){
            console.log('base',child.name )
            //child.layers.enable( BLOOM_SCENE );            
            //child.material.color.setHex(0x0000FF)
             
             const mat = new THREE.MeshPhongMaterial( { color: 0xFFFFFF, transparent: false, opacity:.8} );       
             mat.shininess = .8
             mat.emissive = new THREE.Color( 0xff0000);
             mat.blending = THREE.NormalBlending;
             child.material = mat;
            
          }

          if(child.name.toLowerCase().indexOf("vetr")!== -1){
            console.log('vetro',child.name )
            child.layers.enable( this.BLOOM_SCENE );
            
             //child.material.color.setHex(0x0000FF)
             //const mat = new THREE.MeshLambertMaterial( { color: 0xFF0000, transparent: false, opacity:0.8} );       
             const mat = new THREE.MeshPhongMaterial( { color: 0xFFFFFF, transparent: false, opacity:.8} );       
             mat.shininess = 60
             mat.emissive = new THREE.Color( 0xffffff);
             mat.emissiveIntensity = .3;
             mat.specular =  new THREE.Color( 0xffffff);
             mat.blending = THREE.NormalBlending;
             child.material = mat;
            
          }


          if(child.name.toLowerCase().indexOf("luce")!== -1){
            console.log('luce',child.name )
            // const light = new THREE.PointLight( 0xFFFF00, 1, 100 );
            // //light.position.set( 50, 50, 50 );
            // child.add( light );
          }

          if(child.name.toLowerCase().indexOf("target_")!== -1){
            let spriteInfo = new THREE.Sprite(material);
            child.visible = false;
            //console.log('anchor', child.name, child.position)
            spriteInfo.scale.set(20, 20, 1);
            spriteInfo.position.x = child.position.x
            spriteInfo.position.y = child.position.y
            spriteInfo.position.z = child.position.z
            spriteInfo.name = child.name;
            
            spriteInfo.layers.enable(0)
            object.add(spriteInfo)
            

            //sprite event inizio
            spriteInfo.cursor = 'pointer';

            spriteInfo.on('click', function (ev) {

              
              const event = new CustomEvent("3dworld:click", {
                detail: {
                  source:  ev.target.name
                }
              });
              window.dispatchEvent(event)
            });
            //Sprite event fine
            //sprites.push(spriteInfo);
          }
        }

      });

      // loader.load('Non ottimizzato_02_nuvole.glb', (gltf) => {
      //   const clouds = gltf.scene        
      //   clouds.traverse((child) => {
        
       
      //     if ( child instanceof THREE.Mesh  ) {
      //       child.material.color.setHex(0xFFFFFF)
      //       const mat = new THREE.MeshLambertMaterial( { color: 0xFF0000.fg, transparent: true, opacity:0.5} );           
      //       mat.blending = THREE.AdditiveBlending;
      //       child.material = mat;

      //     }});
        
      //   object.add(clouds);
      // });

      
      //object.receiveShadow = true;
      //object.castShadow = true;

      scene.add(object);
 

      this.sprite = sprite;
      this.world = object;
      this.autoanimation = true;
      this.restartAutoanimation = null;

      this.contentLoaded();

    }, (d) => {}, (err) => {
      console.log(err);
    })

    const renderer = new THREE.WebGLRenderer({   
      antialias: true,
      alpha: true
    });
    
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(this.wrapper.clientWidth, this.wrapper.clientHeight);
    renderer.setClearColor(0x000000, 0);
    //renderer.toneMapping = THREE.ReinhardToneMapping;
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;


    const controls = new OrbitControls(camera, renderer.domElement);
    controls.minDistance = this.props.config.orbitControls.minDistance;
    controls.maxDistance = this.props.config.orbitControls.maxDistance;
    controls.target.set(0, 0, 0);
    controls.enablePan = false;
    controls.update();

    // const controls = new TrackballControls(camera, renderer.domElement);
    // controls.minDistance = this.props.config.orbitControls.minDistance;
    // controls.maxDistance = this.props.config.orbitControls.maxDistance;
    
    // controls.rotateSpeed = 1.0;
		// controls.zoomSpeed = 1.2;
		//   controls.panSpeed = 0.8;

		// 		controls.keys = [ 'KeyA', 'KeyS', 'KeyD' ];
    



    


    const renderScene = new RenderPass( scene, camera );

    const bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
    bloomPass.threshold = params.bloomThreshold;
    bloomPass.strength = params.bloomStrength;
    bloomPass.radius = params.bloomRadius;

    const bloomComposer = new EffectComposer( renderer );
    bloomComposer.renderToScreen = false;
    bloomComposer.addPass( renderScene );
    bloomComposer.addPass( bloomPass );
    this.bloomComposer = bloomComposer;

   

    const vertexShader = `    
      varying vec2 vUv;
			void main() {
				vUv = uv;
				gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
			}
    `

    const fragmentShader = `
    
    uniform sampler2D baseTexture;
			uniform sampler2D bloomTexture;
			varying vec2 vUv;
			void main() {
				gl_FragColor = ( texture2D( baseTexture, vUv ) + vec4( 1.0 ) * texture2D( bloomTexture, vUv ) );
			}`


    const finalPass = new ShaderPass(
      new THREE.ShaderMaterial( {
        uniforms: {
          baseTexture: { value: null },
          bloomTexture: { value: bloomComposer.renderTarget2.texture }
        },
        vertexShader: vertexShader,
        fragmentShader: fragmentShader,
        defines: {}
      } ), "baseTexture"
    );

    finalPass.needsSwap = true;

    const finalComposer = new EffectComposer( renderer );
    finalComposer.addPass( renderScene );
    finalComposer.addPass( finalPass );


    controls.addEventListener('change', () => {
      this.dirLight.position.copy(camera.position);

      // console.log("🌏", this.world.rotation.x,this.world.rotation.y,this.world.rotation.z);
      // console.log("🎥", this.camera.rotation.x,this.camera.rotation.y,this.camera.rotation.z);

        this.autoanimation = false;
        if(this.restartAutoanimation){
          clearTimeout(this.restartAutoanimation)
        }
        this.restartAutoanimation = setTimeout( ()=>{
          this.autoanimation = true
        }, 1000);

    });

    this.enableBloom = false;

    

    this.scene = scene;
    this.camera = camera;
    this.dirLight = dirLight;
    this.controls = controls;
    this.renderer = renderer;
    
    this.finalComposer = finalComposer;

    this.darkMaterial = darkMaterial;
    this.materials = materials;
    this.bloomLayer = bloomLayer;

    this.clock = new THREE.Clock();


    this.mount.appendChild(this.renderer.domElement)
    this.start();

    this.interaction = new Interaction(renderer, scene, camera);
    window.addEventListener('resize', this.onWindowResize, false);
  }

  onWindowResize() {
    this.camera.aspect = this.wrapper.clientWidth / this.wrapper.clientHeight;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(this.wrapper.clientWidth, this.wrapper.clientHeight);

    this.bloomComposer.setSize( this.wrapper.clientWidth,  this.wrapper.clientHeight);
    this.finalComposer.setSize( this.wrapper.clientWidth,  this.wrapper.clientHeight);

  }

  componentWillUnmount() {
    this.stop()
    this.mount.removeChild(this.renderer.domElement)
  }

  start() {
    if (!this.frameId) {
      this.frameId = requestAnimationFrame(this.animate)
    }
  }

  stop() {
    cancelAnimationFrame(this.frameId)
  }

  animate() {
   
    this.frameId = window.requestAnimationFrame(this.animate);
    const delta = this.clock.getDelta();

    if (!!this.world && this.autoanimation) {
       this.world.rotation.y += 0.002;
    }


    if(this.mixer) {
      this.mixer.update( delta );
    }
    this.controls.update()
    this.renderScene()


    
  }

  renderScene() {
    if(this.enableBloom){
     this.renderBloom( true );
     this.finalComposer.render();
    }else{
      this.renderer.render(this.scene, this.camera)
    }


    // this.renderer.autoClear = false;
    // this.renderer.clear();
    
    // this.camera.layers.set(this.BLOOM_SCENE);
    // this.finalComposer.render();
    // //this.renderBloom( true );
    
    // this.renderer.clearDepth();
    // this.camera.layers.set(this.ENTIRE_SCENE);
    // this.renderer.render(this.scene, this.camera)


   

  }

  darkenNonBloomed( obj ) {

    if ( obj.isMesh && this.bloomLayer.test( obj.layers ) === false ) {

      this.materials[ obj.uuid ] = obj.material;
      obj.material = this.darkMaterial;

    }

  }

  restoreMaterial( obj ) {

    if (  this.materials[ obj.uuid ] ) {
      obj.material =  this.materials[ obj.uuid ];
      delete  this.materials[ obj.uuid ];

    }

  }

  renderBloom( mask ) {

    if ( mask === true ) {

     // this.scene.traverse( this.darkenNonBloomed.bind(this) );
      this.bloomComposer.render();
      this.scene.traverse( this.restoreMaterial.bind(this) );
     } 
    else {

      this.camera.layers.set( this.BLOOM_SCENE );
      this.bloomComposer.render();
      this.camera.layers.set( this.ENTIRE_SCENE );

    }

  }

  render() {
    return (
      <div className="content-3dworld" ref = {
        (mount) => {
          this.mount = mount
        }
      }
      />
    )
  }
}

export default ThreeDimensionalWorld