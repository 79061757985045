import React, { useState } from 'react'

import ThreeDimensionalWorld from '../ThreeDimensionalWorld';

import './style.css';

function App(props) {
  const [loading, setLoading] = useState(true);

  const contentLoaded = () => {
    const event = new Event('3dworld:loaded');
    window.dispatchEvent(event);

    setLoading(false);
  }

  const { config } = props;

  return (
    <>
      <ThreeDimensionalWorld className={!!loading ? 'd-none' : ''}
        contentLoaded={contentLoaded}
        config={config} />
    </>
  )
}


export default App