import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App/index';

// import reportWebVitals from './reportWebVitals';




const threeDimensionalWorld = (override) => {

  const config = {
    wrapper: 'wrapper-3dworld',
    path: !!process.env.PUBLIC_URL ? process.env.PUBLIC_URL : '.',
    camera: {
      current: 600,
    },
    orbitControls: {
      minDistance: 400,
      maxDistance: 900,
    },
    ...override
  }

  config.container = document.getElementById(config.wrapper);

  config.camera.current = config.camera.current <= 1800 ? config.camera.current : 1800;
  config.orbitControls.maxDistance = config.orbitControls.maxDistance <= 1800 ? config.orbitControls.maxDistance : 1800;


  ReactDOM.render(
    <React.StrictMode>
      <App config={config} />
    </React.StrictMode>,
    config.container
  );
}

window.threeDimensionalWorld = threeDimensionalWorld;

// reportWebVitals();
